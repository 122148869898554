import { createSelector } from 'reselect';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

const createEventSelector = () => createSelector(
  ({ event }) => event,
  event => event,
);

export const useEvent = () => {
  const selector = useMemo(createEventSelector, []);
  return useSelector(selector);
};
