import * as React from 'react';
import { useDispatch } from 'react-redux';
import Layout from '../../components/Layout.js';
import UserInput from '../../components/forms/inputs/UserInput.js';
import { setEventId } from '../../stores/Event/EventActions.js';
import FormButton from '../../components/forms/inputs/FormButton.js';
import { useEvent } from '../../stores/Event/EventSelectors.js';

const EventIdForm = () => {
  const dispatch = useDispatch();
  const { id } = useEvent();

  const onSubmit = e => {
    e.preventDefault();
    window.location = `/banners/events/${id}`;
  };

  const onChange = value => {
    dispatch(setEventId(value));
  };

  return (
    <form onSubmit={onSubmit} className="event-form">
      <UserInput type="text">
        <UserInput.Label text="" />
        <UserInput.Input value={id} onTextChange={onChange} placeholder="Enter event ID here..." />
      </UserInput>
      <FormButton onPress={onSubmit}>
        Go
      </FormButton>
    </form>
  );
};

const EventsBannersPage = () => {
  return (
    <Layout title={{ black: 'Configure', blue: 'Event Banners' }}>
      <EventIdForm />
    </Layout>
  );
};

export default EventsBannersPage;
