import React, { createContext, useContext } from 'react';
import { v4 as uuid } from 'uuid';

const UserInputContext = createContext({});

const UserInputProvider = ({ type, children }) => {
  const state = {
    id: uuid(),
    type,
  };

  return (
    <UserInputContext.Provider value={state}>
      {children}
    </UserInputContext.Provider>
  );
};

export default UserInputProvider;

export const useUserInput = () => useContext(UserInputContext);
