import { SET_EVENT, SET_EVENT_ID, SET_STATUS } from './EventReducer.js';
import Status from '../Status.js';
import ApiService from '../../services/ApiService.js';
import { setErrors } from '../Errors/ErrorsActions.js';
import { batch } from 'react-redux';

export const setEventId = id => ({ type: SET_EVENT_ID, id });

const setStatus = status => ({ type: SET_STATUS, status });

const setEvent = event => ({ type: SET_EVENT, event });

const setEventNotFoundError = () => dispatch => {
  batch(() => {
    dispatch(setErrors([
      'Event not found. Go back and try again.',
    ]));
    dispatch(setStatus(Status.Error));
  });
};

export const loadEvent = id => async dispatch => {
  dispatch(setStatus(Status.Loading));

  try {
    const { event, area, competitions, sport, message, errors = [] } = await ApiService.getInstance().getEvent(id);

    if (message || errors.length) {
      dispatch(setEventNotFoundError());
    } else {
      dispatch(setEvent({ ...event, area, competitions, sport }));
    }

  } catch (e) {
    dispatch(setEventNotFoundError());
  }
};
