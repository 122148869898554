import React from 'react';
import UserInputProvider, { useUserInput } from './UserInputProvider.js';

const UserInput = ({ type, info, error, children }) => {
  return (
    <UserInputProvider type={type}>
      <div className="user-input">
        {children}
        {error
          ? <UserInput.Error message={error} />
          : info
            ? <UserInput.Info text={info} />
            : null}
      </div>
    </UserInputProvider>
  );
};

/* eslint react-hooks/rules-of-hooks: "off" */
UserInput.Label = ({ text, disabled = false, children }) => {
  const { id, type } = useUserInput();

  return (
    <label
      htmlFor={id}
      className={type === 'checkbox' ? 'user-input__checkbox-label' : 'user-input__label'}
    >
      {children}
      <span className={disabled ? 'user-input__disabled-label' : undefined}>{text}</span>
    </label>
  );
};

/* eslint react-hooks/rules-of-hooks: "off" */
UserInput.Input = ({ onTextChange, ...props }) => {
  const { id, type } = useUserInput();
  const onChange = e => onTextChange(e.target.value);

  return (
    <input
      id={id}
      type={type}
      className={type === 'checkbox' ? 'user-input__checkbox' : 'user-input__input'}
      onChange={onChange}
      {...props}
    />
  );
};

UserInput.Error = ({ message }) => {
  return (
    <div className="user-input__error">
      {message}
    </div>
  );
};

UserInput.Info = ({ text }) => {
  return (
    <div className="user-input__info">
      {text}
    </div>
  );
};

/* eslint react-hooks/rules-of-hooks: "off" */
UserInput.Checkbox = ({ checked, setChecked, disabled = false }) => {
  const { id } = useUserInput();

  const onChange = () => {
    setChecked(!checked);
  };

  return (
    <input disabled={disabled} checked={checked} onChange={onChange} id={id} type="checkbox" className="user-input__checkbox" />
  );
};

export default UserInput;
