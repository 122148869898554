import React from 'react';
import Status from '../../../stores/Status.js';

const FormButton = ({ status, onPress, isDisabled, children }) => {
  const isLoading = status === Status.Loading;

  return (
    <div className="form-button-container">
      <button className="form-button" onClick={onPress} disabled={isDisabled || isLoading}>
        {isLoading ? 'Loading...' : children}
      </button>
    </div>
  );
};

export default FormButton;
